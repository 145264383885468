<template>
  <div class="h-100">
    <div>
      <b-form
        ref="form"
        :style="{ height: trHeight }"
        class="repeater-form"
        @submit.prevent="addItem"
        @change="formChanged()"
      >
        <!-- Row Loop -->
        <b-container
          v-for="(item, mainStepIndex) in items"
          :id="item.serial"
          :key="item.serial"
          ref="row"
          class="pb-2"
        >
          <div class="d-flex border mb-1">
            <div class="flex-grow-1 p-1">
              <b-row>
                <b-col md="12">
                  <h3>Main Operation Step #{{ mainStepIndex + 1 }}</h3>
                </b-col>
                <!-- Main operation step -->
                <b-col md="3">
                  <b-form-group label="Main Operation Step" label-for="Operation-name">
                    <b-form-input
                      v-model="item.name"
                      id="Operation-name"
                      type="text"
                      placeholder="Ex : Cutting"
                    />
                  </b-form-group>
                </b-col>
                <!-- work station -->
                <b-col lg="3" md="1">
                  <b-form-group label="Work Station" label-for="WorkStation">
                    <b-form-input
                      v-model="item.workstation"
                      id="WorkStation"
                      placeholder="WorkStation"
                    />
                  </b-form-group>
                </b-col>
                <!-- num of cycles -->
                <b-col md="3">
                  <b-form-group label="Number of Cycles" label-for="cycles">
                    <b-form-input
                      v-model="item.cycles"
                      id="cycles"
                      type="number"
                      placeholder="1 cycle"
                      min="1"
                      max="100"
                    />
                  </b-form-group>
                </b-col>
                <!-- total cycle time -->
                <b-col md="3">
                  <b-form-group label="Total Cycle Time" label-for="cycleHours">
                    <b-form-input
                      v-model="item.cycleHours"
                      id="cycleHours"
                      type="number"
                      placeholder="1 hour"
                      min="1"
                      max="100"
                      :disabled="item.substeps && item.substeps.length > 0"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Sub Step fields -->
              <div v-if="item.substeps && item.substeps.length > 0" class="my-1">
                <b-button @click="toggleSubStep(mainStepIndex)">
                  {{ openSubSteps[mainStepIndex] ? "Hide" : "Show" }} Sub Operation Steps
                </b-button>
              </div>
              <div v-if="openSubSteps[mainStepIndex]">
                <b-card
                  v-for="(substep, substepIndex) in item.substeps"
                  :key="substepIndex"
                  class="border shadow-none"
                  no-body
                >
                  <b-card-body class="d-flex p-0">
                    <div class="flex-grow-1 p-1">
                      <b-row>
                        <b-col md="12">
                          <h3>Sub Operation Step #{{ substepIndex + 1 }}</h3>
                        </b-col>
                        <!-- Sub Operation Step Name -->
                        <b-col md="3">
                          <b-form-group
                            label="Sub Operation Step Name"
                            label-for="subOpStepName"
                          >
                            <b-form-input
                              id="subOpStepName"
                              type="text"
                              placeholder="Ex : Cutting"
                              v-model="substep.name"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- Sub Operation Work Station -->
                        <b-col md="3">
                          <b-form-group label="Work Station" label-for="WorkStationSubOp">
                            <b-form-input
                              id="WorkStationSubOp"
                              placeholder="WorkStation"
                              v-model="substep.workstation"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- Sub Operation Num of Cycles -->
                        <b-col md="3">
                          <b-form-group label="Number of Cycles" label-for="cyclesSupOp">
                            <b-form-input
                              id="cyclesSupOp"
                              type="number"
                              placeholder="1 cycle"
                              min="1"
                              v-model="substep.cycles"
                              max="100"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- Sub Operation Cycle Time -->
                        <b-col md="3">
                          <b-form-group label="Cycle Time" label-for="cycleHoursSubOp">
                            <b-form-input
                              id="cycleHoursSubOp"
                              type="number"
                              placeholder="1 hour"
                              min="1"
                              v-model="substep.cycleHours"
                              max="100"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- labour -->
                        <b-col md="3">
                          <b-form-group label="Labour" label-for="labourId">
                            <b-form-select
                              v-model="substep.labourId"
                              id="labourId"
                              :options="labours"
                              value-field="id"
                              text-field="labourName"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- labour cost -->
                        <b-col md="3">
                          <b-form-group label="Labour Cost" label-for="labourCost">
                            <b-form-input
                              v-model="substep.labourCost"
                              id="labourCost"
                              type="number"
                              placeholder="Rs 10"
                              readonly
                            />
                          </b-form-group>
                        </b-col>
                        <!-- labour qty -->
                        <b-col md="3">
                          <b-form-group
                            label="Labour Qty"
                            label-for="labourQty"
                            placeholder="Labour Quantity"
                          >
                            <b-form-input
                              id="labourQty"
                              type="number"
                              v-model="substep.labourQty"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- total labour cost -->
                        <b-col md="3">
                          <b-form-group
                            label="Total Labour Cost"
                            label-for="totalLabourCost"
                          >
                            <b-form-input
                              id="totalLabourCost"
                              v-model="substep.totalLabourCost"
                              type="number"
                              readonly
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <!-- equipment row -->
                      <b-row
                        v-for="(equipment, equipmentIndex) in substep.equipments"
                        :key="equipmentIndex"
                      >
                        <!-- equipment -->
                        <b-col md="3">
                          <b-form-group label="Equipment" label-for="equipmentId">
                            <b-form-select
                              v-model="equipment.equipmentId"
                              id="equipmentId"
                              :options="equipments"
                              value-field="id"
                              text-field="name"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- equipment cost -->
                        <b-col md="3">
                          <b-form-group label="Equipment Cost" label-for="equipmentCost">
                            <b-form-input
                              v-model="equipment.equipmentCost"
                              id="equipmentCost"
                              type="number"
                              placeholder="Equipment Cost"
                              readonly
                            />
                          </b-form-group>
                        </b-col>
                        <!-- equipment qty -->
                        <b-col md="2">
                          <b-form-group label="Equipment Qty" label-for="equipmentQty">
                            <b-form-input
                              id="equipmentQty"
                              type="number"
                              placeholder="Equipment Qty"
                              v-model="equipment.equipmentQty"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- total equipment cost -->
                        <b-col md="3">
                          <b-form-group
                            label="Total Equipment Cost"
                            label-for="totalEquipmentCost"
                          >
                            <b-form-input
                              id="totalEquipmentCost"
                              type="number"
                              readonly
                              v-model="equipment.totalEquipmentCost"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col md="1" class="d-flex flex-shrink-1 align-items-center">
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="
                              removeEquipment(mainStepIndex, substepIndex, equipmentIndex)
                            "
                          />
                        </b-col>
                      </b-row>
                      <!-- /equipment row -->
                      <!-- Cost Row -->
                      <b-row>
                        <!-- Direct Cost -->
                        <b-col md="4">
                          <b-form-group label="Direct Cost" label-for="directCostSubOp">
                            <b-form-input
                              id="directCostSubOp"
                              type="number"
                              placeholder="Rs 10"
                              v-model.number="substep.directCost"
                              readonly
                            />
                          </b-form-group>
                        </b-col>
                        <!-- Indirect Cost -->
                        <b-col md="4">
                          <b-form-group
                            label="Indirect Cost"
                            label-for="indirectCostSubOp"
                          >
                            <b-form-input
                              id="indirectCostSubOp"
                              type="number"
                              placeholder="Rs 10"
                              v-model.number="substep.indirectCost"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="4">
                          <b-form-group
                            label="Total Cost (Rs)"
                            label-for="totalCostSubOp"
                          >
                            <b-form-input
                              id="totalCostSubOp"
                              type="number"
                              placeholder="Rs 10"
                              v-model="substep.totalCost"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>

                    <div
                      class="d-flex flex-column justify-content-between border-left ml-1 px-25 py-50"
                    >
                      <feather-icon
                        size="16"
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="removeSubStep(mainStepIndex, substepIndex)"
                      />

                      <b-dropdown variant="link" no-caret toggle-class="p-0 " right>
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item
                          @click="addEquipment(mainStepIndex, substepIndex)"
                        >
                          Add Equipment
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </b-card-body>
                </b-card>
              </div>
              <!-- / Sub step fields -->

              <b-row>
                <!-- duration -->
                <b-col md="2">
                  <b-form-group label="Total Duration" label-for="duration">
                    <b-form-input
                      v-model.number="item.duration"
                      id="duration"
                      type="number"
                      placeholder="1 hour"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <!-- total direct cost -->
                <b-col md="2">
                  <b-form-group label="Total Direct Cost" label-for="directCost">
                    <b-form-input
                      v-model.number="item.directCost"
                      :disabled="item.substeps && item.substeps.length > 0"
                      id="directCost"
                      type="number"
                      placeholder="Rs 10"
                    />
                  </b-form-group>
                </b-col>
                <!-- total indirect cost -->
                <b-col md="2">
                  <b-form-group label="Total Indirect Cost" label-for="indirectCost">
                    <b-form-input
                      v-model="item.indirectCost"
                      id="indirectCost"
                      type="number"
                      placeholder="Rs 10"
                      :disabled="item.substeps && item.substeps.length > 0"
                    />
                  </b-form-group>
                </b-col>
                <!-- indirect cost name -->
                <b-col md="3">
                  <b-form-group label="Indirect Cost Name" label-for="indirectCostName">
                    <b-form-input
                      v-model="item.indirectCostName"
                      id="indirectCostName"
                      type="text"
                      placeholder="Indirect Cost"
                    />
                  </b-form-group>
                </b-col>
                <!-- total cost -->
                <b-col md="3">
                  <b-form-group label="Total Cost (Rs)" label-for="totalCost">
                    <b-form-input
                      v-model="item.totalCost"
                      id="totalCost"
                      value="Rs20"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <div>
              <div
                class="d-flex flex-column justify-content-between border-left ml-1 px-25 py-50 h-100"
              >
                <feather-icon
                  size="16"
                  icon="XIcon"
                  class="cursor-pointer"
                  @click="removeItem(mainStepIndex)"
                />

                <b-dropdown variant="link" no-caret toggle-class="p-0 " right>
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="addSubstep(mainStepIndex)">
                    Add Operation Substep
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </b-container>
      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="addItem"
    >
      <feather-icon icon="PlusIcon" class="mr-25" />
      <span>Add Main Operation Steps</span>
    </b-button>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BContainer,
  BPopover,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardBody,
  BCardTitle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BForm,
    BFormSelect,
    BRow,
    vSelect,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BContainer,
    BPopover,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardBody,
    BCardTitle,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [],
      openSubSteps: {},
      nextSerial: 1,
    };
  },

  methods: {
    ...mapActions("labourModule", ["getLabourListAction"]),
    ...mapActions("equipmentModule", ["getEquipmentListAction"]),

    addItem() {
      let item = {
        serial: this.nextSerial,
        name: null,
        workstation: null,
        cycles: 1,
        cycleHours: 1,
        duration: 0,
        directCost: null,
        indirectCost: null,
        indirectCostName: null,
        totalCost: 0,
        substeps: [],
      };
      this.items.push(item);
      this.nextSerial += 1;

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        this.initTrHeight();
      });
    },
    addSubstep(mainStepIndex) {
      const substep = {
        labourId: null,
        name: null,
        workstation: null,
        indirectCostName: null,
        labourCost: null,
        labourQty: null,
        name: null,
        totalCost: null,
        directCost: null,
        indirectCost: null,
        cycles: 1,
        cycleHours: 1,
        totalLabourCost: null,
        equipments: [
          {
            equipmentId: null,
            equipmentCost: null,
            equipmentQty: null,
            totalEquipmentCost: null,
          },
        ],
      };
      this.items[mainStepIndex].substeps.push(substep);
      this.nextSerial += 1;
      this.calculateDuration(mainStepIndex);
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        this.initTrHeight();
      });
    },

    removeSubStep(mainStepIndex, substepIndex) {
      // Cache the substep's cycle time and cycle hours before removing
      const removedSubstep = this.items[mainStepIndex].substeps[substepIndex];
      const removedDuration =
        (removedSubstep.cycles || 1) * (removedSubstep.cycleHours || 1);

      // Remove the substep
      this.items[mainStepIndex].substeps.splice(substepIndex, 1);

      // Deduct the cycle time of the removed substep from the mainStep duration
      this.items[mainStepIndex].duration -= removedDuration;

      // Update directCost and totalCost
      if (this.items[mainStepIndex].substeps.length === 0) {
        this.items[mainStepIndex].directCost = 0;
      } else {
        this.items[mainStepIndex].substeps.forEach((_, i) => {
          this.calculateCosts(mainStepIndex, i);
        });
      }

      this.trTrimHeight(this.$refs.row[0].offsetHeight);
      this.initTrHeight();
      this.formChanged();
    },
    addEquipment(mainStepIndex, substepIndex) {
      const equipment = {
        equipmentId: null,
        equipmentCost: null,
        equipmentQty: null,
        totalEquipmentCost: null,
      };
      this.items[mainStepIndex].substeps[substepIndex].equipments.push(equipment);
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[mainStepIndex].offsetHeight);
        this.initTrHeight();
      });
    },

    removeEquipment(mainStepIndex, substepIndex, equipmentIndex) {
      this.items[mainStepIndex].substeps[substepIndex].equipments.splice(
        equipmentIndex,
        1
      );
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
      this.initTrHeight();
      this.formChanged();
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
      this.initTrHeight();
      this.formChanged();
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    formChanged() {
      this.$emit("onchange", this.items);
    },
    toggleSubStep(index) {
      this.$set(this.openSubSteps, index, !this.openSubSteps[index]);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
      this.initTrHeight();
    },
    calculateDuration(mainStepIndex) {
      let mainStep = this.items[mainStepIndex];
      let duration = 0;
      let totalCycleTime = 0;
      mainStep.substeps.forEach((substep) => {
        duration += (substep.cycles || 1) * (substep.cycleHours || 1);
        totalCycleTime += (parseFloat(substep.cycleHours) || 1) * (substep.cycles || 1);
      });
      mainStep.cycleHours = totalCycleTime;
      mainStep.duration = duration * (mainStep.cycles || 1);
    },

    calculateCosts(mainStepIndex, substepIndex) {
      let mainStep = this.items[mainStepIndex];
      let substep = mainStep.substeps[substepIndex];
      substep.cycles = substep.cycles || 1;
      substep.cycleHours = substep.cycleHours || 1;
      let selectedLabour = this.labours.find((obj) => obj.id === substep.labourId);
      if (selectedLabour) {
        substep.labourCost = selectedLabour.costPerHour || 0;
      }
      substep.labourQty = substep.labourQty || 1;
      substep.totalLabourCost =
        substep.labourCost * substep.labourQty * substep.cycleHours * substep.cycles;
      substep.totalEquipmentCost = 0;
      substep.equipments.forEach((equipment, equipmentIndex) => {
        let selectedEquipment = this.equipments.find(
          (obj) => obj.id === equipment.equipmentId
        );
        if (selectedEquipment) {
          equipment.equipmentCost = selectedEquipment.costPerHour || 0;
        }
        equipment.equipmentQty = equipment.equipmentQty || 1;
        equipment.totalEquipmentCost =
          equipment.equipmentCost *
          equipment.equipmentQty *
          substep.cycleHours *
          substep.cycles;
        substep.totalEquipmentCost += equipment.totalEquipmentCost;
      });

      substep.directCost = substep.totalLabourCost + substep.totalEquipmentCost;
      substep.totalCost =
        parseFloat(substep.directCost) + (parseFloat(substep.indirectCost) || 0);
      mainStep.directCost = mainStep.substeps.reduce((acc, ss) => acc + ss.directCost, 0);
      mainStep.indirectCost = mainStep.substeps.reduce(
        (acc, ss) => acc + (parseFloat(ss.indirectCost) || 0),
        0
      );
      mainStep.totalCost =
        parseFloat(mainStep.directCost) + (parseFloat(mainStep.indirectCost) || 0);
      this.calculateDuration(mainStepIndex);
    },

    updateTotalCostSub(mainStepIndex, substepIndex) {
      let mainStep = this.items[mainStepIndex];
      let substep = mainStep.substeps[substepIndex];
      substep.totalCost =
        (parseFloat(substep.directCost) || 0) + (parseFloat(substep.indirectCost) || 0);
    },

    updateTotalCost(index) {
      let item = this.items[index];
      if (item.substeps.length > 0) {
        item.directCost = item.substeps.reduce((sum, substep) => {
          return (
            sum +
            (parseFloat(substep.totalLabourCost) || 0) +
            (parseFloat(substep.totalEquipmentCost) || 0)
          );
        }, 0);
      }
      item.duration = (item.cycles || 1) * (item.cycleHours || 1);
      item.totalCost =
        (parseFloat(item.directCost) || 0) + (parseFloat(item.indirectCost) || 0);
    },
  },
  watch: {
    items: {
      handler: function (newVal, oldVal) {
        newVal.forEach((mainStep, mainStepIndex) => {
          if (
            mainStep.substeps.length > 0 &&
            oldVal[mainStepIndex].substeps.length === 0
          ) {
            this.$set(this.items[mainStepIndex], "directCost", 0);
            this.$set(this.items[mainStepIndex], "cycleHours", 0);
          }
          mainStep.substeps.forEach((substep, substepIndex) => {
            this.calculateCosts(mainStepIndex, substepIndex);
            this.calculateDuration(mainStepIndex);
            this.updateTotalCostSub(mainStepIndex, substepIndex);
          });
          this.updateTotalCost(mainStepIndex);
        });
      },
      deep: true,
    },
  },
  async mounted() {
    this.initTrHeight();
    await this.getLabourListAction();
    await this.getEquipmentListAction();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  computed: {
    ...mapGetters("labourModule", {
      labours: "labour",
      loading: "loading",
    }),
    ...mapGetters("equipmentModule", {
      equipments: "equipments",
      loading: "loading",
    }),
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  height: 100%;
  transition: 0.35s height;
}
</style>
