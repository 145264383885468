<template>
  <b-form @submit.prevent="formSubmitted" @change="formChanged()">
    <b-row>
      <b-col md="6">
        <b-form-group label="Product Name" label-for="mc-Product-name">
          <b-form-input
            id="mc-Product-name"
            placeholder="Product Name"
            v-model="details.name"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Product Classification" label-for="mc-Product-name">
          <b-form-select v-model="details.type" :options="productTypes" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-for="mc-stock-threshold" label="Unit Of Measure">
          <div class="form-label-group">
            <b-form-select
              v-model="details.uom"
              :options="itemUoms"
              value-field="id"
              text-field="name"
            />
          </div>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group label="Product Code" label-for="mc-Product-Code">
          <b-form-input
            id="mc-Product-Code"
            placeholder="Product Code"
            v-model="details.code"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Barcode" label-for="mc-Barcode">
          <b-form-input
            id="mc-Barcode"
            type="text"
            placeholder="Barcode"
            v-model="details.barcode"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-for="mc-stock-threshold" label="Low Stock Alert">
          <div class="form-label-group">
            <b-form-input
              id="mc-stock"
              type="text"
              placeholder="Low Stock Threshold"
              v-model="details.lowStock"
            />
          </div>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Category" label-for="mc-Category">
          <b-form-select
            v-model="details.categoryId"
            :options="itemCategories"
            value-field="id"
            text-field="name"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Preferred Supplier" label-for="mc-Supplier">
          <b-form-select
            v-model="details.supplierId"
            :options="suppliers"
            value-field="id"
            text-field="name"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" class="mb-2">
        <label for="textarea-default">Product Description</label>
        <b-form-textarea
          id="textarea-default"
          placeholder="Product Description"
          rows="5"
          v-model="details.description"
        />
      </b-col>
      <b-col cols="12">
        <label for="textarea-default">Product Image</label>
        <b-form-file
          v-model="details.file"
          placeholder="Choose a product picture or drop it here..."
          drop-placeholder="Drop picture here..."
        />

        <b-card-text class="my-2">
          Selected Image:
          <strong>{{ details.file ? details.file.name : "" }}</strong>
        </b-card-text>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormTextarea,
  BFormSelect,
  BFormFile,
  BCardText,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BFormSelect,
    BCol,
    ToastificationContent,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormTextarea,
    BButton,
    BCardText,
  },
  directives: {
    Ripple,
  },
  methods: {
    formSubmitted() {
      this.$router.push("/product/list/");
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Product Successfully Added",
          icon: "EditIcon",
          variant: "success",
        },
      }),
        this.$bvModal.hide("modal-1");
    },
    ...mapActions("itemUomModule", ["getItemUOMListAction"]),
    ...mapActions("itemcategoryModule", ["getItemCategoryListAction"]),
    ...mapActions("supplierModule", ["getSupplierListAction"]),
    formChanged() {
      this.$emit("onchange", this.details);
    },
  },
  data() {
    return {
      productTypes: [
        { value: 1, text: "Finished" },
        { value: 2, text: "Semi Finished" },
      ],
      details: {
        name: null,
        type: null,
        code: null,
        barcode: null,
        uom: null,
        lowStock: null,
        productionLimit: null,
        categoryId: null,
        supplierId: null,
        description: null,
        letter: null,
        file: null,
      },
      lowStockError: null,
    };
  },
  computed: {
    ...mapGetters("itemUomModule", {
      itemUoms: "itemuom",
      loading: "loading",
    }),
    ...mapGetters("itemcategoryModule", {
      itemCategories: "itemcategories",
      loading: "loading",
    }),
    ...mapGetters("supplierModule", {
      suppliers: "suppliers",
      loading: "loading",
    }),
  },
  async mounted() {
    await this.getItemUOMListAction();
    await this.getItemCategoryListAction();
    await this.getSupplierListAction();
  },
};
</script>
