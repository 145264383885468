<template>
  <div>
    <div>
      <b-form
        ref="form"
        :style="{ height: trHeight }"
        class="repeater-form"
        @submit.prevent="addItem"
        @change="formChanged()"
      >
        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item.serial"
          :key="item.serial"
          ref="row"
        >
          <!-- Item Name -->
          <b-col md="3">
            <b-form-group label="Raw Material / Work In Progress" label-for="materialId">
              <b-form-select
                v-model="item.id"
                id="materialId"
                name="materialId"
                :options="itemOptions"
                value-field="id"
                text-field="name"
                @change="itemChanged(item)"
              />
            </b-form-group>
          </b-col>
          <!-- Quantity -->
          <b-col md="2">
            <b-form-group label="Unit of Measure" label-for="materialUom">
              <b-form-select
                v-model="item.uom"
                id="materialUom"
                name="materialUom"
                :options="itemUoms"
                value-field="id"
                text-field="name"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Qty" label-for="materialQty">
              <b-form-input
                v-model="item.qty"
                id="materialQty"
                name="materialQty"
                type="number"
                min="1"
                max="100"
                placeholder=""
                @change="itemChanged(item)"
              />
            </b-form-group>
          </b-col>
          <!-- Profession -->
          <b-col lg="2" md="2">
            <b-form-group label="Total Cost (Rs)" label-for="materialCost">
              <b-form-input
                v-model="item.totalCost"
                id="materialCost"
                name="materialCost"
                value="32$"
              />
            </b-form-group>
          </b-col>
          <!-- Remove Button -->
          <b-col lg="3" md="3" class="mb-50">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
              <span>Delete</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>
      <div class="d-flex justify-content-center mb-1">
        <b-spinner
          variant="success"
          label="Spinning"
          block
          v-if="materialLoading || productLoading || uomLoading"
          style="width: 3rem; height: 3rem"
        ></b-spinner>
      </div>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="addItem"
    >
      <feather-icon icon="PlusIcon" class="mr-25" />
      <span>Add Bill Of Materials</span>
    </b-button>
  </div>
</template>
<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BFormTextarea,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BForm,
    BRow,
    vSelect,
    BCol,
    BButton,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [],
      nextSerial: 1,
      itemOptions: [],
    };
  },
  async mounted() {
    this.initTrHeight();
    await this.getRawMaterialListAction().then(() => {
      this.materials.map((x) => {
        if (x.defaultUomID) {
          this.itemOptions.push({
            id: x.id,
            name: x.name,
            defaultUomID: x.defaultUomID,
            salesPrice: x.salesPrice,
            purchasePrice: x.purchasePrice,
          });
        }
      });
    });
    await this.getProductListAction().then(() => {
      this.products.map((x) => {
        if (x.defaultUomId && x.type === 2) {
          this.itemOptions.push({
            id: x.id,
            name: x.name,
            defaultUomID: x.defaultUomId,
            salesPrice: x.salesPrice,
            purchasePrice: x.purchasePrice,
          });
        }
      });
    });
    await this.getItemUOMListAction();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    addItem() {
      let item = {
        serial: this.nextSerial,
        id: null,
        uom: null,
        qty: null,
        cost: null,
        totalCost: 0,
      };
      this.items.push(item);
      this.nextSerial += 1;

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);

      this.$nextTick(() => {
        this.formChanged();
      });
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    ...mapActions("rawmaterialModule", ["getRawMaterialListAction"]),
    ...mapActions("productModule", ["getProductListAction"]),
    ...mapActions("itemUomModule", ["getItemUOMListAction"]),
    formChanged() {
      this.$emit("onchange", this.items);
    },
    itemChanged(item) {
      let selectedMaterial = this.itemOptions.filter((obj) => {
        return obj.id === item.id;
      });
      if (selectedMaterial.length > 0) {
        item.uom = selectedMaterial[0].defaultUomID ?? selectedMaterial[0].defaultUomId;
        item.cost =
          (selectedMaterial[0].purchasePrice ?? 0) == 0
            ? selectedMaterial[0].salePrice ?? 0
            : selectedMaterial[0].purchasePrice ?? 0;
        item.totalCost = ((item.qty ?? 0) * (item.cost ?? 0)).toFixed(2);
      }
    },
  },
  computed: {
    ...mapGetters("rawmaterialModule", {
      materials: "rawmaterials",
      materialLoading: "loading",
    }),
    ...mapGetters("productModule", {
      products: "product",
      productLoading: "loading",
    }),
    ...mapGetters("itemUomModule", {
      itemUoms: "itemuom",
      uomLoading: "loading",
    }),
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
