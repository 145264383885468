<template>
  <div class="main">
    <div class="d-flex justify-content-center" v-if="loadingSpinner">
      <b-spinner
        variant="success"
        label="Spinning"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <div v-if="!loadingSpinner">
      <b-card class="my-2">
        <b-row>
          <b-col>
            <h2 class="text-primary m-0 p-0">Product manufactured inhouse?</h2>
          </b-col>
          <b-col cols="12" class="d-flex align-items-center justify-content-start my-1">
            <label for="check-button" class="mr-2 text-primary">No</label>
            <b-form-checkbox
              name="check-button"
              switch
              inline
              v-model="product.inHouseManufactured"
              disabled
            >
            </b-form-checkbox>
            <label for="check-button" class="text-primary">Yes</label>
          </b-col>
          <b-col>
            <h3></h3>
          </b-col>
        </b-row>
      </b-card>
      <form-wizard
        color="#B1D06C"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Finish"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="formSubmitted"
        ref="formWiz"
        v-if="product.inHouseManufactured == true"
      >
        <!-- Search details tab -->
        <tab-content title="Details">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group label="Product Name" label-for="mc-Product-name">
                  <b-form-input
                    id="mc-Product-name"
                    type="text"
                    v-model="product.name"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Product classification" label-for="mc-Product-name">
                  <b-form-input
                    id="mc-Product-name"
                    type="text"
                    :value="getTypeNameById(product.type)"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Product Code" label-for="mc-Product-Code">
                  <b-form-input
                    id="mc-Product-Code"
                    type="text"
                    v-model="product.code"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Barcode" label-for="mc-Barcode">
                  <b-form-input
                    id="mc-Barcode"
                    type="text"
                    v-model="product.barcode"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label-for="mc-stock-threshold" label="Unit Of Measure">
                  <div class="form-label-group">
                    <b-form-input
                      id="mc-Barcode"
                      type="text"
                      :value="product.uomName"
                      readonly
                    />
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label-for="mc-stock-threshold" label="Low Stock Threshold">
                  <div class="form-label-group">
                    <b-form-input
                      id="mc-stock"
                      type="text"
                      v-model="product.lowStock"
                      readonly
                    />
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label-for="batchqty" label="Batch Quantity">
                  <b-form-input
                    id="mc-Product-batchqty"
                    v-model="product.productionLimit"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Category" label-for="mc-Category">
                  <b-form-input
                    id="mc-Category"
                    v-model="product.categoryName"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Preferred Supplier" label-for="mc-Supplier">
                  <b-form-input
                    id="mc-Supplier"
                    type="text"
                    :value="getSupplierNameById(product.supplierId)"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" class="mb-2">
                <label for="textarea-default">Product Description</label>
                <b-form-textarea
                  id="textarea-default"
                  value="Lorem"
                  rows="5"
                  v-model="product.description"
                  readonly
                />
              </b-col>
              <b-col cols="12" v-if="product.productImage">
                <b-form-group label="Product Image">
                  <img :src="'data:image/jpeg;base64,' + product.productImage" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </tab-content>
        <tab-content title="Bill Of Materials">
          <b-form
            ref="form"
            :style="{ height: trHeight }"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >
            <!-- Row Loop -->
            <b-row v-for="(item, index) in sortBom" :id="index" :key="index">
              <b-col md="3">
                <b-form-group
                  label="Raw Material / Work In Progress"
                  label-for="Operation-name"
                >
                  <b-form-input :value="getMaterialNameById(item.materialId)" readonly />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Unit of Measure" label-for="cost">
                  <b-form-input :value="getUomNameById(item.itemUomId)" readonly />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Qty" label-for="cost">
                  <b-form-input
                    id="cost"
                    type="number"
                    v-model="item.quantity"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <!-- Profession -->
              <b-col lg="2" md="2">
                <b-form-group label="Total Cost" label-for="price">
                  <b-form-input id="price" v-model="item.totalCost" readonly />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
          </b-form>
        </tab-content>
        <!-- Operation Steps Tab -->
        <tab-content title="Operation Steps">
          <b-form
            ref="form"
            :style="{ height: trHeight }"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >
            <b-container
              ref="row"
              class="pb-2"
              v-for="(item, index) in sortedOperationSteps"
              :id="index"
              :key="index"
            >
              <div class="d-flex border mb-1">
                <div class="flex-grow-1 p-1">
                  <b-row>
                    <b-col md="12">
                      <h3>Main Operation Step # {{ index + 1 }}</h3>
                    </b-col>
                    <!-- Main operation step -->
                    <b-col md="3">
                      <b-form-group
                        label="Main Operation Step"
                        label-for="Operation-name"
                      >
                        <b-form-input
                          id="Operation-name"
                          type="text"
                          placeholder="Ex : Cutting"
                          v-model="item.name"
                          readonly
                        />
                      </b-form-group>
                    </b-col>
                    <!-- work station -->
                    <b-col lg="3" md="1">
                      <b-form-group label="Work Station" label-for="WorkStation">
                        <b-form-input
                          id="WorkStation"
                          placeholder="WorkStation"
                          v-model="item.workstation"
                          readonly
                        />
                      </b-form-group>
                    </b-col>
                    <!-- num of cycles -->
                    <b-col md="3">
                      <b-form-group label="Number of Cycles" label-for="cycles">
                        <b-form-input
                          id="cycles"
                          type="number"
                          placeholder="1 cycle"
                          v-model="item.cycles"
                          readonly
                        />
                      </b-form-group>
                    </b-col>
                    <!-- total cycle time -->
                    <b-col md="3">
                      <b-form-group label="Total Cycle Time" label-for="cycleHours">
                        <b-form-input
                          id="cycleHours"
                          type="number"
                          placeholder="1 hour"
                          v-model="item.cycleHours"
                          readonly
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- Sub Step fields -->

                  <b-card
                    class="border shadow-none"
                    no-body
                    v-for="(subOp, subIndex) in item.subOperations"
                    :key="`sub-${subIndex}`"
                  >
                    <b-card-body class="d-flex p-0">
                      <div class="flex-grow-1 p-1">
                        <b-row>
                          <b-col md="12">
                            <h3>Sub Operation Step # {{ subIndex + 1 }}</h3>
                          </b-col>
                          <!-- Sub Operation Step Name -->
                          <b-col md="3">
                            <b-form-group
                              label="Sub Operation Step Name"
                              label-for="subOpStepName"
                            >
                              <b-form-input
                                id="subOpStepName"
                                type="text"
                                readonly
                                v-model="subOp.name"
                              />
                            </b-form-group>
                          </b-col>
                          <!-- Sub Operation Work Station -->
                          <b-col md="3">
                            <b-form-group
                              label="Work Station"
                              label-for="WorkStationSubOp"
                            >
                              <b-form-input
                                id="WorkStationSubOp"
                                placeholder="WorkStation"
                                v-model="subOp.workstation"
                                readonly
                              />
                            </b-form-group>
                          </b-col>
                          <!-- Sub Operation Num of Cycles -->
                          <b-col md="3">
                            <b-form-group
                              label="Number of Cycles"
                              label-for="cyclesSupOp"
                            >
                              <b-form-input
                                id="cyclesSupOp"
                                type="number"
                                placeholder="1 cycle"
                                min="1"
                                max="100"
                                v-model="subOp.cycles"
                                readonly
                              />
                            </b-form-group>
                          </b-col>
                          <!-- Sub Operation Cycle Time -->
                          <b-col md="3">
                            <b-form-group label="Cycle Time" label-for="cycleHoursSubOp">
                              <b-form-input
                                id="cycleHoursSubOp"
                                type="number"
                                placeholder="1 hour"
                                min="1"
                                max="100"
                                v-model="subOp.cycleHours"
                                readonly
                              />
                            </b-form-group>
                          </b-col>
                          <!-- labour -->
                          <b-col md="3">
                            <b-form-group label="Labour" label-for="labourId">
                              <b-form-input
                                :value="getLabourNameById(subOp.labourId)"
                                readonly
                              />
                            </b-form-group>
                          </b-col>
                          <!-- labour cost -->
                          <b-col md="3">
                            <b-form-group label="Labour Cost" label-for="labourCost">
                              <b-form-input
                                id="labourCost"
                                type="number"
                                placeholder="Rs 10"
                                v-model="subOp.labourCost"
                                readonly
                              />
                            </b-form-group>
                          </b-col>
                          <!-- labour qty -->
                          <b-col md="3">
                            <b-form-group
                              label="Labour Qty"
                              label-for="labourQty"
                              placeholder="Labour Quantity"
                            >
                              <b-form-input
                                id="labourQty"
                                type="number"
                                v-model="subOp.labourQuantity"
                                readonly
                              />
                            </b-form-group>
                          </b-col>
                          <!-- total labour cost -->
                          <b-col md="3">
                            <b-form-group
                              label="Total Labour Cost"
                              label-for="totalLabourCost"
                            >
                              <b-form-input
                                id="totalLabourCost"
                                type="number"
                                readonly
                                v-model="subOp.totalLabourCost"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <!-- equipment row -->
                        <b-row
                          v-for="(
                            equipment, equipmentIndex
                          ) in subOp.itemSubOperationEquipment"
                          :key="`sub-${equipmentIndex}`"
                        >
                          <b-col md="3">
                            <b-form-group label="Equipment" label-for="equipmentId">
                              <b-form-input
                                :value="getEquipmentNameById(equipment.equipmentId)"
                                readonly
                              />
                            </b-form-group>
                          </b-col>
                          <!-- equipment cost -->
                          <b-col md="3">
                            <b-form-group
                              label="Equipment Cost"
                              label-for="equipmentCost"
                            >
                              <b-form-input
                                id="equipmentCost"
                                type="number"
                                placeholder="Equipment Cost"
                                v-model="equipment.equipmentCost"
                                readonly
                              />
                            </b-form-group>
                          </b-col>
                          <!-- equipment qty -->
                          <b-col md="3">
                            <b-form-group label="Equipment Qty" label-for="equipmentQty">
                              <b-form-input
                                id="equipmentQty"
                                type="number"
                                readonly
                                v-model="equipment.equipmentQuantity"
                              />
                            </b-form-group>
                          </b-col>
                          <!-- total equipment cost -->
                          <b-col md="3">
                            <b-form-group
                              label="Total Equipment Cost"
                              label-for="totalEquipmentCost"
                            >
                              <b-form-input
                                id="totalEquipmentCost"
                                type="number"
                                v-model="equipment.totalEquipmentCost"
                                readonly
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <!-- /equipment row -->
                        <!-- Cost Row -->
                        <b-row>
                          <!-- Direct Cost -->
                          <b-col md="4">
                            <b-form-group label="Direct Cost" label-for="directCostSubOp">
                              <b-form-input
                                id="directCostSubOp"
                                type="number"
                                placeholder="Rs 10"
                                readonly
                                v-model="subOp.directCost"
                              />
                            </b-form-group>
                          </b-col>
                          <!-- Indirect Cost -->
                          <b-col md="4">
                            <b-form-group
                              label="Indirect Cost"
                              label-for="indirectCostSubOp"
                            >
                              <b-form-input
                                id="indirectCostSubOp"
                                type="number"
                                placeholder="Rs 10"
                                readonly
                                v-model="subOp.indirectCost"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col md="4">
                            <b-form-group
                              label="Total Cost (Rs)"
                              label-for="totalCostSubOp"
                            >
                              <b-form-input
                                id="totalCostSubOp"
                                type="number"
                                placeholder="Rs 10"
                                readonly
                                v-model="subOp.totalCost"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <!-- /Cost Row -->
                      </div>
                    </b-card-body>
                  </b-card>

                  <!-- / Sub step fields -->

                  <b-row>
                    <b-col md="2">
                      <b-form-group label="Total Duration" label-for="duration">
                        <b-form-input
                          id="duration"
                          type="number"
                          v-model="item.duration"
                          readonly
                        />
                      </b-form-group>
                    </b-col>
                    <!-- total direct cost -->
                    <b-col md="2">
                      <b-form-group label="Total Direct Cost" label-for="directCost">
                        <b-form-input
                          id="directCost"
                          type="number"
                          placeholder="Rs 10"
                          v-model="item.directCost"
                          readonly
                        />
                      </b-form-group>
                    </b-col>
                    <!-- total indirect cost -->
                    <b-col md="2">
                      <b-form-group label="Total Indirect Cost" label-for="indirectCost">
                        <b-form-input
                          id="indirectCost"
                          type="number"
                          placeholder="Rs 10"
                          v-model="item.indirectCost"
                          readonly
                        />
                      </b-form-group>
                    </b-col>
                    <!-- indirect cost name -->
                    <b-col md="3">
                      <b-form-group
                        label="Indirect Cost Name"
                        label-for="indirectCostName"
                      >
                        <b-form-input
                          id="indirectCostName"
                          type="text"
                          placeholder="Indirect Cost"
                          v-model="item.inDirectCostName"
                          readonly
                        />
                      </b-form-group>
                    </b-col>
                    <!-- total cost -->
                    <b-col md="3">
                      <b-form-group label="Total Cost (Rs)" label-for="totalCost">
                        <b-form-input
                          id="totalCost"
                          value="Rs20"
                          v-model="item.totalCost"
                          readonly
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
                <div></div>
              </div>
            </b-container>
          </b-form>
        </tab-content>
        <tab-content title="Pricing">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Total Cost (Rs)"
                  label-for="h-number"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-number"
                    type="number"
                    v-model="product.purchasePrice"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Percentage Mark Up (%)"
                  label-for="h-password"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-Mark"
                    type="number"
                    v-model="product.markUpPercentage"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Sales VAT" label-for="h-Tax" label-cols-md="4">
                  <b-form-input
                    id="h-Mark"
                    type="number"
                    v-model="product.saleTaxPercentage"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Calculate Selling Price (Rs)"
                  label-for="h-Selling"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-Mark"
                    type="number"
                    v-model="product.salePrice"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </tab-content>
        <tab-content title="Quality Control">
          <b-form ref="form" :style="{ height: trHeight }" class="repeater-form">
            <!-- Row Loop -->
            <b-row
              ref="row"
              v-for="(step, stepIndex) in product.operationSteps"
              :id="stepIndex"
              :key="stepIndex"
            >
              <b-row
                ref="row"
                v-for="(item, index) in step.itemOperationQuality"
                :id="index"
                :key="index"
              >
                <!-- Item Name -->
                <b-col md="2">
                  <b-form-group label="Operation Step" label-for="Operation-step">
                    <b-form-input
                      id="Operation-step"
                      type="text"
                      value="Step 1"
                      v-model="step.name"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Quality Name" label-for="Quality-name">
                    <b-form-input
                      id="Cost-name"
                      type="text"
                      v-model="item.name"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Minimum Value" label-for="cost">
                    <b-form-input
                      id="Cost Amount"
                      type="text"
                      v-model="item.minValue"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Maximum Value" label-for="cost">
                    <b-form-input
                      id="Cost Amount"
                      type="text"
                      v-model="item.maxValue"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
            </b-row>
          </b-form>
        </tab-content>
      </form-wizard>
      <form-wizard
        color="#B1D06C"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Finish"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="formSubmitted"
        ref="formWiz"
        v-if="
          product.inHouseManufactured === null || product.inHouseManufactured == false
        "
      >
        <!-- Search details tab -->
        <tab-content title="Details">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group label="Product Name" label-for="mc-Product-name">
                  <b-form-input
                    id="mc-Product-name"
                    type="text"
                    v-model="product.name"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Product classification" label-for="mc-Product-name">
                  <b-form-input
                    id="mc-Product-name"
                    type="text"
                    :value="getTypeNameById(product.type)"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Product Code" label-for="mc-Product-Code">
                  <b-form-input
                    id="mc-Product-Code"
                    type="text"
                    v-model="product.code"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Barcode" label-for="mc-Barcode">
                  <b-form-input
                    id="mc-Barcode"
                    type="text"
                    v-model="product.barcode"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label-for="mc-stock-threshold" label="Unit Of Measure">
                  <div class="form-label-group">
                    <b-form-input
                      id="mc-Barcode"
                      type="text"
                      :value="product.uomName"
                      readonly
                    />
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label-for="mc-stock-threshold" label="Low Stock Threshold">
                  <div class="form-label-group">
                    <b-form-input
                      id="mc-stock"
                      type="text"
                      v-model="product.lowStock"
                      readonly
                    />
                  </div>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group label="Category" label-for="mc-Category">
                  <b-form-input
                    id="mc-Category"
                    v-model="product.categoryName"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Preferred Supplier" label-for="mc-Supplier">
                  <b-form-input
                    id="mc-Supplier"
                    type="text"
                    :value="getSupplierNameById(product.supplierId)"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" class="mb-2">
                <label for="textarea-default">Product Description</label>
                <b-form-textarea
                  id="textarea-default"
                  value="Lorem"
                  rows="5"
                  v-model="product.description"
                  readonly
                />
              </b-col>

              <b-col cols="12" v-if="product.productImage">
                <b-form-group label="Product Image">
                  <img :src="'data:image/jpeg;base64,' + product.productImage" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </tab-content>
        <tab-content title="Pricing">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Total Cost (Rs)"
                  label-for="h-number"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-number"
                    type="number"
                    v-model="product.purchasePrice"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Percentage Mark Up (%)"
                  label-for="h-password"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-Mark"
                    type="number"
                    v-model="product.markUpPercentage"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Sales Tax" label-for="h-Tax" label-cols-md="4">
                  <b-form-input
                    id="h-Mark"
                    type="number"
                    v-model="product.saleTaxPercentage"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Calculate Selling Price (Rs)"
                  label-for="h-Selling"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="h-Mark"
                    type="number"
                    v-model="product.salePrice"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </tab-content>
      </form-wizard>
    </div>
  </div>
</template>

<script>
import ConfirmProduct from "@/views/inventory/products/AddProduct/ConfirmProduct.vue";
import AddProduct from "@/views/inventory/products/AddProduct/addproduct.vue";
import buildprocess from "@/views/inventory/products/AddProduct/buildprocess.vue";
import AddProductBuy from "@/views/inventory/products/AddProduct/AddProductBuy.vue";
import OtherCosts from "@/views/inventory/products/AddProduct/OtherCosts.vue";
import AddBOM from "@/views/inventory/products/AddProduct/AddBOM.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import { heightTransition } from "@core/mixins/ui/transition";
import { mapActions, mapGetters } from "vuex";
import {
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BContainer,
  BCard,
  BSpinner,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormSelect,
  BFormFile,
  BFormDatepicker,
  BCardText,
} from "bootstrap-vue";
import { codeIcon } from "@/views/inventory/products/AddProduct/LeadsWizard.js";

export default {
  components: {
    FormWizard,
    BContainer,
    buildprocess,
    AddBOM,
    BButton,
    BFormCheckbox,
    BSpinner,
    BCard,
    TabContent,
    BRow,
    OtherCosts,
    AddProduct,
    BCol,
    AddProductBuy,
    ConfirmProduct,
    ToastificationContent,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormSelect,
    BFormFile,
    BFormDatepicker,
    vSelect,
    BCardText,
  },
  mixins: [heightTransition],
  data() {
    return {
      id: this.$route.params.id,
      codeIcon,
      loadingSpinner: true,
      productTypes: [
        { value: 1, text: "Finished" },
        { value: 2, text: "Semi Finished" },
        { value: 3, text: "Raw Material" },
        { value: 4, text: "Service" },
      ],
    };
  },

  methods: {
    //route pages to leads
    neckStep() {
      this.$refs.formWiz.nextTab();
    },
    formSubmitted() {
      this.$router.push({ name: "product-list" });
      this.$bvModal.hide("modal-1");
    },
    ...mapActions("productModule", ["getProductIDAction"]),
    ...mapActions("rawmaterialModule", ["getRawMaterialListAction"]),
    ...mapActions("itemUomModule", ["getItemUOMListAction"]),
    ...mapActions("itemcategoryModule", ["getItemCategoryListAction"]),
    ...mapActions("supplierModule", ["getSupplierListAction"]),
    ...mapActions("labourModule", ["getLabourListAction"]),
    ...mapActions("equipmentModule", ["getEquipmentListAction"]),
    getTypeNameById(id) {
      let type = this.productTypes.filter((obj) => {
        return obj.value === id;
      });
      if (type.length > 0) {
        return type[0].text;
      }
      return null;
    },
    getMaterialNameById(id) {
      let material = this.materials.filter((obj) => {
        return obj.id === id;
      });
      if (material.length > 0) {
        return material[0].name;
      }
      return null;
    },
    getUomNameById(id) {
      let uom = this.itemUoms.filter((obj) => {
        return obj.id === id;
      });
      if (uom.length > 0) {
        return uom[0].name;
      }
      return null;
    },
    getCategoryNameById(id) {
      let category = this.itemCategories.filter((obj) => {
        return obj.id === id;
      });
      if (category.length > 0) {
        return category[0].name;
      }
      return null;
    },
    getSupplierNameById(id) {
      let supplier = this.suppliers.filter((obj) => {
        return obj.id === id;
      });
      if (supplier.length > 0) {
        return supplier[0].name;
      }
      return null;
    },
    getLabourNameById(id) {
      let labour = this.labours.filter((obj) => {
        return obj.id === id;
      });
      if (labour.length > 0) {
        return labour[0].labourName;
      }
      return null;
    },
    getEquipmentNameById(id) {
      let equipment = this.equipments.filter((obj) => {
        return obj.id === id;
      });
      if (equipment.length > 0) {
        return equipment[0].name;
      }
      return null;
    },
    getDirectCost(item) {
      return (
        item.labourCost * item.cycles * item.cycleHours +
        item.equipmentCost * item.cycles * item.cycleHours
      );
    },
    getMaterialCost(item) {
      let material = this.materials.filter((obj) => {
        return obj.id === item.materialId;
      });
      if (material.length > 0) {
        let cost =
          (material[0].purchasePrice ?? 0) == 0
            ? material[0].salePrice ?? 0
            : material[0].purchasePrice ?? 0;
        return (item.quantity ?? 0) * cost;
      }
    },
  },
  async mounted() {
    setTimeout(() => {
      this.loadingSpinner = false;
    }, 5000);

    await this.getProductIDAction(this.id)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        this.product = [];
        // alert(error.message);
        this.$swal({
          title: "Error!",
          html: error.message,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });

    await this.getRawMaterialListAction();
    await this.getItemUOMListAction();
    await this.getItemCategoryListAction();
    await this.getSupplierListAction();
    await this.getLabourListAction();
    await this.getEquipmentListAction();
  },
  computed: {
    sortedOperationSteps: function () {
      function compare(a, b) {
        if (a.itemOperationOrder < b.itemOperationOrder) return -1;
        if (a.itemOperationOrder > b.itemOperationOrder) return 1;
        return 0;
      }

      return this.product.operationSteps.sort(compare);
    },

    sortBom: function () {
      function compare(a, b) {
        if (a.itemBOMOrder < b.itemBOMOrder) return -1;
        if (a.itemBOMOrder > b.itemBOMOrder) return 1;
        return 0;
      }
      return this.product.billOfMaterials.sort(compare);
    },
    ...mapGetters("productModule", {
      product: "productID",
      loading: "loading",
    }),
    ...mapGetters("rawmaterialModule", {
      materials: "rawmaterials",
      loading: "loading",
    }),
    getImageUrl() {
      const imageType = this.product.productImageName.split(".").pop();
      const dataUrlPrefix = `data:image/${imageType};base64,`;
      const imageUrl = dataUrlPrefix + this.product.productImage;

      return imageUrl;
    },
    ...mapGetters("itemUomModule", {
      itemUoms: "itemuom",
      loading: "loading",
    }),
    ...mapGetters("itemcategoryModule", {
      itemCategories: "itemcategories",
      loading: "loading",
    }),
    ...mapGetters("supplierModule", {
      suppliers: "suppliers",
      loading: "loading",
    }),
    ...mapGetters("labourModule", {
      labours: "labour",
      loading: "loading",
    }),
    ...mapGetters("equipmentModule", {
      equipments: "equipments",
      loading: "loading",
    }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style scoped>
label {
  font-size: 1.714rem !important;
  font-weight: 500;
  margin-bottom: 0;
}
</style>
