<template>
  <b-form @submit.prevent @change="formChanged()">
    <b-row>
      <b-col cols="12">
        <b-form-group label="Total Cost (Rs)" label-for="h-number" label-cols-md="4">
          <b-form-input
            id="h-number"
            type="number"
            value="5982"
            v-model="Product.purchasePrice"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Percentage Mark Up (%)"
          label-for="h-password"
          label-cols-md="4"
        >
          <b-form-input
            id="h-Mark"
            type="number"
            placeholder="Ex : 10%"
            v-model="Product.markUpPercentage"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
          <b-form-group
            label="Sales VAT"
            label-for="h-VAT"
            label-cols-md="4"
          >
            <b-form-input
              id="h-Mark"
              type="number"
              placeholder="Ex : 10%"
              v-model="Product.saleTaxPercentage"
            />
          </b-form-group>
        </b-col>
      <b-col cols="12">
        <b-form-group
          label="Calculate Selling Price (Rs)"
          label-for="h-Selling"
          label-cols-md="4"
        >
          <b-form-input
            id="h-Mark"
            type="number"
            placeholder="Rs 6895"
            v-model="Product.salePrice"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    Product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formChanged() {
      this.$emit("onchange", this.Product);
    },
  },
  watch: {
    Product(newProduct, oldProduct) {
      console.log(newProduct);
      this.formChanged();
    },
  },
};
</script>
